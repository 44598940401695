@import 'public/styles/breakpoint';
@import 'public/styles/colors';

.is-application-avatar {
  &.has-max-width {
    max-height: 7rem;
  }

  .application-avatar {
    height: 100%;
    max-height: 7rem;
    max-width: 7rem;
  }
}

@media screen and (max-width: $max-width) {
  div.preview {
    display: none;
  }
}

#type {
  border-radius: 4px;
  box-shadow: none;
  background-color: $background_form_color;
  border-color: $border_form_color;
  border-radius: 4px;
  color: $text_form_color;
  width: 100%;
  height: 2.25rem;
}

#type:hover {
  border-color: $border_hover_form_color;
}

#integration {
  border-radius: 4px;
  box-shadow: none;
  background-color: $background_form_color;
  border-color: $border_form_color;
  border-radius: 4px;
  color: $text_form_color;
  width: 100%;
  height: 2.25rem;
}

#integration:hover {
  border-color: $border_hover_form_color;
}

